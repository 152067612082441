<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <BackButton :handler="backToReports" />
      </v-col>
    </v-row>

    <div class="app mt-4">
      <div class="user-wrapper">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>
          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>
          <div class="md-card md-theme-default rounded-5 shadow-0">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col>Service Report </v-col>
                  <v-spacer></v-spacer>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission($modules.reports.service.slug)
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders shadow-0"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="md-content md-table-content md-scrollbar md-theme-default"
                  >
                    <div
                      v-resize="onResize"
                      :style="{ height: `${containerHeight}px` }"
                    >
                      <table class="logTable">
                        <thead
                          class="md-card-header shadow-0 sticky header-fixed"
                        >
                          <tr>
                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label cursor-pointer">
                                  Timestamp
                                </div>
                              </div>
                            </th>
                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label cursor-pointer">
                                  Service
                                </div>
                              </div>
                            </th>

                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">
                                  Product Type
                                </div>
                              </div>
                            </th>

                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Quantity</div>
                              </div>
                            </th>
                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Price</div>
                              </div>
                            </th>
                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">Tax</div>
                              </div>
                            </th>
                            <th class="md-table-head sticky">
                              <div
                                class="md-table-head-container md-ripple md-disabled"
                              >
                                <div class="md-table-head-label">
                                  Grand Total
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody
                          :set="
                            ((service = null),
                            (total_quantity = 0),
                            (total_price = 0),
                            (total_tax = 0),
                            (grand_total = 0),
                            (created_at = ''))
                          "
                          v-if="venueServiceReport.length"
                        >
                          <template v-for="(data, lndex) in venueServiceReport">
                            <tr
                              :key="`dayTotal_${lndex}`"
                              class="md-table-row font-weight-black grand_total_bg"
                              v-if="data.order_date != created_at && lndex != 0"
                            >
                              <td class="md-table-cell" colspan="3">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  <strong>TOTAL</strong>
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ quantity | formatDecimalNumber }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{ price.toFixed(2) | toCurrency }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{ tax.toFixed(2) | toCurrency }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{ total.toFixed(2) | toCurrency }}
                                </div>
                              </td>
                            </tr>
                            <tr
                              :key="`obj_head_${lndex}`"
                              class="head_bg"
                              v-if="data.order_date != created_at"
                            >
                              <span
                                :colspan="6"
                                :set="
                                  ((service = ''),
                                  (product_type = ''),
                                  (created_at = data.order_date),
                                  (quantity = 0),
                                  (price = 0),
                                  (tax = 0),
                                  (total = 0))
                                "
                              >
                              </span>
                            </tr>

                            <tr
                              class="md-table-row"
                              :key="`data_${lndex}`"
                              v-if="data.quantity > 0"
                            >
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ created_at | reportDateformat }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center font-weight-black"
                                  v-if="data.service_name != service"
                                >
                                  <span
                                    data-v-b74a88c0=""
                                    class="orange--text"
                                    :set="(service = data.service_name)"
                                  >
                                    {{ data.service_name }}
                                  </span>
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    data.order_date != null
                                      ? data.product_type
                                      : "--"
                                  }}
                                </div>
                              </td>

                              <td
                                class="md-table-cell"
                                :set="
                                  ((total_quantity += data.quantity),
                                  (quantity += data.quantity))
                                "
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    data.quantity != null
                                      ? data.quantity
                                      : 0 | formatDecimalNumber
                                  }}
                                </div>
                              </td>
                              <td
                                class="md-table-cell"
                                :set="
                                  ((total_price += data.price),
                                  (price += data.price))
                                "
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{
                                    data.price != null
                                      ? data.price.toFixed(2)
                                      : 0
                                  }}
                                </div>
                              </td>
                              <td
                                class="md-table-cell"
                                :set="
                                  ((total_tax += data.tax), (tax += data.tax))
                                "
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{
                                    data.tax != null ? data.tax.toFixed(2) : 0
                                  }}
                                </div>
                              </td>
                              <td
                                class="md-table-cell"
                                :set="
                                  ((grand_total += data.total),
                                  (total += data.total))
                                "
                              >
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{
                                    data.total != null
                                      ? data.total.toFixed(2)
                                      : 0
                                  }}
                                </div>
                              </td>
                            </tr>
                          </template>
                          <tr
                            :key="`dayTotal_last_row`"
                            class="md-table-row font-weight-black grand_total_bg"
                          >
                            <td class="md-table-cell" colspan="3">
                              <div class="md-table-cell-container text-center">
                                <strong>TOTAL</strong>
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ quantity | formatDecimalNumber }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ price.toFixed(2) | toCurrency }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ tax.toFixed(2) | toCurrency }}
                              </div>
                            </td>
                            <td class="md-table-cell">
                              <div class="md-table-cell-container text-center">
                                {{ currencyCode }}
                                {{ total.toFixed(2) | toCurrency }}
                              </div>
                            </td>
                          </tr>
                          <tr :key="`obj_head_date`" class="head_bg">
                            <td
                              :colspan="7"
                              :set="((service = ''), (product_type = ''))"
                            >
                              <div
                                class="md-table-cell-container text-center font-weight-black"
                              >
                                Bills for Date:
                                {{ searchParams.from_date | reportDateformat }}
                                to
                                {{ searchParams.to_date | reportDateformat }}
                              </div>
                            </td>
                          </tr>
                          <template
                            v-for="(data, lndex) in venueServiceReportTotal"
                          >
                            <tr
                              class="md-table-row"
                              :key="`total_${lndex}`"
                              v-if="data.quantity > 0"
                            >
                              <td></td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center font-weight-black"
                                  v-if="data.service_name != service"
                                >
                                  <span
                                    data-v-b74a88c0=""
                                    class="orange--text"
                                    :set="(service = data.service_name)"
                                  >
                                    {{ data.service_name }}
                                  </span>
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    data.order_date != null
                                      ? data.product_type
                                      : "--"
                                  }}
                                </div>
                              </td>

                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{
                                    data.quantity != null
                                      ? data.quantity
                                      : 0 | formatDecimalNumber
                                  }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{
                                    data.price != null
                                      ? data.price.toFixed(2)
                                      : 0
                                  }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{
                                    data.tax != null ? data.tax.toFixed(2) : 0
                                  }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  {{ currencyCode }}
                                  {{
                                    data.total != null
                                      ? data.total.toFixed(2)
                                      : 0
                                  }}
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                        <tbody v-else>
                          <tr>
                            <td :colspan="6">
                              <v-card-text class="pa-8 text-center">
                                <h3>No data found!</h3>
                              </v-card-text>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table class="sticky_bottom logTable">
                        <tbody>
                          <template v-if="venueServiceReport.length">
                            <tr
                              :key="`grandTotal`"
                              class="md-table-row font-weight-black grand_total_bg"
                            >
                              <td class="md-table-cell" colspan="3" width="50%">
                                <div
                                  class="md-table-cell-container text-center"
                                >
                                  <strong>GRAND TOTAL</strong>
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ total_quantity.toFixed(2) }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ currencyCode }}
                                  {{ total_price.toFixed(2) | toCurrency }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ currencyCode }}
                                  {{ total_tax.toFixed(2) | toCurrency }}
                                </div>
                              </td>
                              <td class="md-table-cell">
                                <div class="md-table-cell-container">
                                  {{ currencyCode }}
                                  {{ grand_total.toFixed(2) | toCurrency }}
                                </div>
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      containerHeight: 400,
      venueServiceReport: [],
      venueServiceReportTotal: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        productTypeId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
    };
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.venueServiceReport.length;
    },
    rowSpan() {
      return parseInt(this.venueServiceReport.length / 4);
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    onResize() {
      this.containerHeight = window.innerHeight - 380;
    },
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/venue-service-report${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            this.venueServiceReport = data["data"];
            this.venueServiceReportTotal = data["total"];
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    exportPdf() {
      const doc = new jsPDF();
      let docName = `Catgeory_report_${moment().format("YYYYMMDDHHmmss")}`;
      const pageWidth = doc.internal.pageSize.getWidth();
      let finalY = doc.lastAutoTable.finalY || 20;
      let rightEnd = pageWidth - 14;
      let center = pageWidth / 2;

      if (this.$store.getters.venueInfo.logo != null) {
        let data = JSON.parse(localStorage.getItem("venue_image_data"));
        if (
          data != null &&
          data.margin > 0 &&
          data.width > 0 &&
          data.height > 0 &&
          data.dataUrl != null
        ) {
          doc.addImage(
            data.dataUrl,
            "JPEG",
            data.margin,
            5,
            data.width,
            data.height
          );
        } else {
          this.$store.dispatch(
            "getImageData",
            this.$store.getters.venueInfo.logo
          );
          doc.text(this.$store.getters.venueInfo.name, center, finalY, {
            align: "center",
          });
        }
      } else {
        doc.text(this.$store.getters.venueInfo.name, center, finalY, {
          align: "center",
        });
      }
      doc.setFontSize(12);
      doc.text(
        "Service Report for " +
          this.services.join(", ").replace(/,(?!.*,)/gim, " and "),
        center,
        finalY + 15,
        {
          align: "center",
        }
      );
      doc.setFontSize(10);
      doc.text(
        "From " + this.date1Formatted + " to " + this.date2Formatted,
        center,
        finalY + 21,
        {
          align: "center",
        }
      );
      doc.line(14, finalY + 25, rightEnd, finalY + 25);

      let head = [
        [
          {
            content: "Category",
            colSpan: 1,
            rowSpan: 2,
            styles: { halign: "center" },
          },
          {
            content: "Date",
            colSpan: 1,
            rowSpan: 2,
            styles: { halign: "center" },
          },
          {
            content: "Payable Amount",
            colSpan: 2,
            styles: { halign: "center" },
          },
          {
            content: "Grand Total",
            colSpan: 1,
            styles: { halign: "center" },
          },
        ],
        [
          {
            content: "Retail",
            colSpan: 1,
            styles: { halign: "center" },
          },
          {
            content: "Activity",
            colSpan: 1,
            styles: { halign: "center" },
          },
          {
            content: "Total Payable",
            colSpan: 1,
            styles: { halign: "center" },
          },
        ],
      ];
      let body = [];
      let rows = this.venueServiceReport.length;
      this.services.forEach((category) => {
        this.venueServiceReport.forEach((element, index) => {
          let row = [];
          if (index % 25 == 0) {
            let rowSpan = rows - index > 25 ? 25 : rows - index;
            row.push({
              content: category,
              rowSpan: rowSpan,
              styles: { halign: "center", valign: "middle" },
            });
          }
          row.push(
            { content: element.Date, styles: { halign: "center" } },
            { content: 0, styles: { halign: "center" } },
            { content: element[category], styles: { halign: "center" } },
            { content: element[category], styles: { halign: "center" } }
          );
          body.push(row);
        });
        let total = this.venueServiceReport.reduce(
          (a, b) => a + Number(b[category]),
          0
        );
        body.push([
          {
            content: category + " Total",
            colSpan: 2,
            styles: { halign: "center", fontStyle: "bold" },
          },
          { content: 0, styles: { halign: "center", fontStyle: "bold" } },
          { content: total, styles: { halign: "center", fontStyle: "bold" } },
          { content: total, styles: { halign: "center", fontStyle: "bold" } },
        ]);
      });

      doc.setFontSize(10);
      doc.text("Generated by " + this.$store.getters.userInfo.name, 14, 55, {
        align: "left",
      });
      doc.setFontSize(9);
      doc.text(
        "Report generated on: " + moment().format("Do MMM YYYY hh:mm a"),
        rightEnd,
        55,
        {
          align: "right",
        }
      );

      doc.autoTable({
        startY: 60,
        head: head,
        body: body,
        theme: "grid",
        headStyles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
          lineWidth: 0.5,
          lineColor: [209, 209, 209],
        },
      });

      doc.autoPrint();
      doc.save(`${docName}.pdf`);
      doc.output("dataurlnewwindow");
    },
    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "venue-service-report-pdf";
      } else if (type == "excel") {
        link = "venue-service-report";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.$http
        .get(`venues/reports/` + link + `/download${url}&type=` + type, {
          responseType: "blob",
        })
        .then((response) => {
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Service-Report-" + this.fileName;
      else this.fileName = "Service-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 10px 4px;
  border-radius: 2px;
  background-color: #edf7f8; /* Set color opacity to 10% */
  z-index: 1;
}
.md-card-header {
  background-color: #edf7f8; /* Set color opacity to 10% */
}
</style>
